exports.components = {
  "component---src-containers-404-tsx": () => import("./../../../src/containers/404.tsx" /* webpackChunkName: "component---src-containers-404-tsx" */),
  "component---src-containers-aktivitaeten-container-tsx": () => import("./../../../src/containers/AktivitaetenContainer.tsx" /* webpackChunkName: "component---src-containers-aktivitaeten-container-tsx" */),
  "component---src-containers-engagement-container-tsx": () => import("./../../../src/containers/EngagementContainer.tsx" /* webpackChunkName: "component---src-containers-engagement-container-tsx" */),
  "component---src-containers-index-tsx": () => import("./../../../src/containers/index.tsx" /* webpackChunkName: "component---src-containers-index-tsx" */),
  "component---src-containers-kontakt-container-tsx": () => import("./../../../src/containers/KontaktContainer.tsx" /* webpackChunkName: "component---src-containers-kontakt-container-tsx" */),
  "component---src-containers-spender-container-tsx": () => import("./../../../src/containers/SpenderContainer.tsx" /* webpackChunkName: "component---src-containers-spender-container-tsx" */),
  "component---src-containers-stiftung-container-tsx": () => import("./../../../src/containers/StiftungContainer.tsx" /* webpackChunkName: "component---src-containers-stiftung-container-tsx" */)
}

