export enum ActionTypes {
  SET_AMOUNT = "SET_AMOUNT",
  SET_ADDRESS = "SET_ADDRESS",
  SET_REASON = "SET_REASON",
  ISSUE_PDF = "ISSUE_PDF",
  RESET_PDF = "RESET_PDF"
}

export type Address = {
  city: string;
  zip: string;
  name: string;
  address: string;
};
/**
 * Action Types
 */
export type SetAmount = {
  type: ActionTypes.SET_AMOUNT;
  amount: string | null;
};
export type SetAddress = {
  value: string | number;
  key: keyof Address;
  type: ActionTypes.SET_ADDRESS;
};
export type SetReason = {
  type: ActionTypes.SET_REASON;
  reason: string;
};

export type IssuePDF = {type: ActionTypes.ISSUE_PDF};
export type ResetPDF = {type: ActionTypes.RESET_PDF};

export type EinzahlungsscheinState = {
  amount: string[];
  address: Address;
  reason: string;
  isLoadingPDF: boolean;
};
