import {
  ActionTypes,
  EinzahlungsscheinState,
  IssuePDF,
  ResetPDF,
  SetAddress,
  SetAmount,
  SetReason
} from "./types";

const initialState: EinzahlungsscheinState = {
  address: {city: "", name: "", zip: "", address: ""},
  amount: "",
  reason: "",
  isLoadingPDF: false
};

const einzahlungsscheinReducer = () => {
  return (
    state = initialState,
    action: SetAddress | SetReason | SetAmount | IssuePDF | ResetPDF
  ) => {
    switch (action.type) {
      case ActionTypes.SET_ADDRESS:
        return {
          ...state,
          address: {...state.address, [action.key]: action.value}
        };
      case ActionTypes.SET_AMOUNT:
        return {...state, amount: action.amount};
      case ActionTypes.SET_REASON:
        return {...state, reason: action.reason};
      case ActionTypes.ISSUE_PDF:
        return {...state, isLoadingPDF: true};
      case ActionTypes.RESET_PDF:
        return {...state, isLoadingPDF: false};
      default:
        return state;
    }
  };
};

export default einzahlungsscheinReducer;
