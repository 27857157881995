import {combineReducers} from "redux";
import einzahlungsscheinReducer from "./einzahlungsschein/reducer";
import {EinzahlungsscheinState} from "./einzahlungsschein/types";

export type RootState = {
  einzahlungsschein: EinzahlungsscheinState;
};

export const rootReducer = combineReducers({
  einzahlungsschein: einzahlungsscheinReducer()
});
