import React from "react";
import {Provider} from "react-redux";
import {applyMiddleware, createStore as reduxCreateStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";
import {rootReducer} from "./src/reducers/store";

const createStore = () =>
  reduxCreateStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
export default ({element}) => (
  <Provider store={createStore()}>{element}</Provider>
);
